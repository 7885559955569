import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Grid,
  GridItem,
  AspectRatio
} from '@chakra-ui/react'
import {
  LandingSection,
  LandingSectionContainer,
  LandingSectionTitle
} from '#/src/custom/components/Landing/LandingSection'

import { VideoBox } from 'ui'
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'

const tabs = [
  {
    title: 'Revenue',
    src: 'https://storage.googleapis.com/gecko-media/GRDT/landing/7021892_Business_Growth_3840x2160.mp4',
    effect: 'up'
  },
  {
    title: 'Efficiency',
    src: 'https://storage.googleapis.com/gecko-media/GRDT/landing/7021920_Industry_Future_3840x2160.mp4',
    effect: 'up'
  },
  {
    title: 'Growth',
    src: 'https://storage.googleapis.com/gecko-media/GRDT/landing/7021937_Connection_Cyberspace_3840x2160.mp4',
    effect: 'up'
  },
  {
    title: 'Costs',
    src: 'https://storage.googleapis.com/gecko-media/GRDT/landing/1472616_People_Business_3840x2160.mp4',
    effect: 'down'
  },
  {
    title: 'Risk',
    src: 'https://storage.googleapis.com/gecko-media/GRDT/landing/1474265_People_Business_3840x2160.mp4',
    effect: 'down'
  }
]

export const SectionExpect = () => {
  return (
    <LandingSection>
      <LandingSectionContainer gap="16">
        <LandingSectionTitle>What do you expect from eCommerce?</LandingSectionTitle>

        <Box bg="landing.bg.primary.600" color="text.light" borderRadius="2xl" p={6} w="full">
          <Tabs position="relative" variant="unstyled">
            <Grid
              templateColumns={{
                base: '1fr',
                md: '350px 1fr'
              }}
              gap={8}>
              <GridItem>
                <TabList flexDirection="column" gap={4}>
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.title}
                      fontSize={{
                        base: '2xl',
                        md: '4xl'
                      }}
                      textAlign="left"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap="2"
                      _selected={{
                        color: tab.effect === 'up' ? 'green.400' : 'red'
                      }}>
                      <Box>
                        {tab.effect === 'up' ? <FaLongArrowAltUp /> : <FaLongArrowAltDown />}
                      </Box>

                      <Box color="text.light">{`${tab.title}.`}</Box>
                    </Tab>
                  ))}
                </TabList>
              </GridItem>

              <GridItem>
                <TabPanels>
                  {tabs.map((tab) => (
                    <TabPanel key={tab.title}>
                      <AspectRatio ratio={16 / 9}>
                        <VideoBox
                          source={tab.src}
                          playback
                          controls={false}
                          maxH="100%"
                          maxW="100%"
                        />
                      </AspectRatio>
                    </TabPanel>
                  ))}
                </TabPanels>
              </GridItem>
            </Grid>
          </Tabs>
        </Box>
      </LandingSectionContainer>
    </LandingSection>
  )
}
