import { ButtonProps } from '@chakra-ui/react'

export const landingFormButtonProps = {
  children: 'Send',
  p: {
    base: 8,
    md: 10
  },
  px: {
    base: 10,
    md: 20
  },
  borderRadius: 'xl',
  fontSize: {
    base: 'lg',
    md: '2xl'
  },
  textTransform: 'uppercase',
  fontWeight: 'bold',
  bg: '#181717'
} satisfies ButtonProps

export const landingFormProps = {
  w: 'full',
  display: 'grid',
  gridTemplateColumns: {
    base: '1fr',
    sm: '1fr 1fr'
  },
  gap: {
    base: 12,
    md: 20
  },
  pb: 36,
  sx: {
    position: 'relative',
    '.chakra-input, .chakra-numberinput__field': {
      '--input-height': {
        base: 'var(--chakra-sizes-12)',
        md: 'var(--chakra-sizes-16)'
      },
      '--input-font-size': {
        base: 'var(--chakra-fontSizes-lg)',
        md: 'var(--chakra-fontSizes-xl)'
      },
      '--input-border-radius': 'var(--chakra-sizes-4)',
      color: 'text'
    },
    '.chakra-numberinput': {
      '--number-input-stepper-width': 'var(--chakra-sizes-8)',
      borderRadius: 'var(--chakra-sizes-4)'
    },
    '.chakra-form__label': {
      fontSize: {
        base: 'md',
        md: 'lg'
      }
    },
    '.rf__input-field-wrapper': {
      w: 'auto',
      mt: 0
    },
    '.rf__input-field-wrapper, .chakra-form-control': {
      '.chakra-input:not(:focus-visible)': {
        borderColor: 'transparent'
      },
      '.chakra-textarea:not(:focus-visible)': {
        borderColor: 'transparent'
      }
    },
    '.chakra-form-control': {
      w: 'auto'
    },
    '.chakra-popover__content': {
      minW: 'auto',
      w: 'auto'
    },
    '> *:last-child': {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)'
    }
  }
}
