import { Box, Flex, FlexProps, GridItem, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import {
  LandingSection,
  LandingSectionContainer,
  LandingSectionTitle
} from '#/src/custom/components/Landing/LandingSection'

import story1Img from './images/story_1.png'
import story2Img from './images/story_2.png'
import story3Img from './images/story_3.png'
import story4Img from './images/story_4.png'
import curveImg from './images/curve_separator.svg'
import { GrandImage } from 'ui'
import { Fragment } from 'react'

const items = [
  {
    text: `Combine the power of  PIM together with  Reach  to create compelling product descriptions faster than ever. 
    Effortlessly tailor your content with different tonalities, seasonal themes, and market-specific variations, 
    ensuring your messaging stays fresh, relevant, and impactful—all while saving valuable time.`,
    img: story1Img
  },
  {
    text: `Revolutionize shopping with our Storefront and AI Assistant, combining personalized recommendations, 
    real-time support, and tailored experiences that feel seamless and intuitive. 
    Deliver an engaging journey that delights customers and significantly boosts conversion rates, 
    turning browsers into loyal buyers.`,
    img: story2Img
  },
  {
    text: `Expand into new markets effortlessly with the combined power of PIM, Storefront, and AI Assistant. 
    Launch stores with speed and precision, 
    using properly translated and culturally adapted content tailored for each country. 
    Deliver a localized shopping experience that resonates with customers and drives success in every market.`,
    img: story3Img
  },
  {
    text: `Stay competitive with the synergy of Aurora and Price Optimization. 
    Powered by advanced AI, Aurora analyzes your data and provides actionable insights, automations, and suggestions, 
    while Price Optimization ensures your pricing strategy adapts dynamically to market conditions—keeping you ahead of 
    the competition.`,
    img: story4Img
  }
]

const StoriesSeparator = (props: FlexProps) => {
  return (
    <Flex width="50%" alignSelf="center" flexDirection="column" {...props}>
      <GrandImage src={curveImg} alt="" mb="-1px" />
      <Box width="calc(100% - 139px)" ml="69px" h="1px" bg="#B8B8B8" />
      <GrandImage
        src={curveImg}
        alt=""
        transform="rotate(-180deg)"
        alignSelf="flex-end"
        mt="-1px"
      />
    </Flex>
  )
}

export const SectionStories = () => {
  return (
    <LandingSection>
      <LandingSectionContainer gap={32}>
        <LandingSectionTitle>Some of Our Stories...</LandingSectionTitle>

        <VStack alignItems="stretch" gap={0}>
          {items.map((item, index) => (
            <Fragment key={index}>
              <SimpleGrid
                columns={{
                  base: 1,
                  sm: 2
                }}
                gap={8}
                alignItems="stretch">
                <GridItem
                  as={Flex}
                  order={index % 2 ? 2 : 1}
                  borderRadius="3xl"
                  border="3px solid #EDEDED"
                  p={6}
                  alignItems="stretch"
                  justifyContent="stretch">
                  <Box position="relative" w="100%" h="100%">
                    <GrandImage
                      display={{
                        base: 'block',
                        sm: 'none'
                      }}
                      borderRadius="xl"
                      src={item.img}
                      alt=""
                      fill={false}
                      objectFit="contain"
                    />
                    <GrandImage
                      display={{
                        base: 'none',
                        sm: 'block'
                      }}
                      borderRadius="xl"
                      src={item.img}
                      alt=""
                      fill
                      objectFit="contain"
                    />
                  </Box>
                </GridItem>

                <GridItem
                  order={{
                    base: 2,
                    sm: index % 2 ? 1 : 2
                  }}
                  bg="landing.bg.primary.500"
                  borderRadius="3xl"
                  p={10}
                  color="text.light">
                  <Text
                    textStyle="landingBody"
                    fontSize={{
                      base: 'lg',
                      sm: 'xl',
                      md: '2xl'
                    }}>
                    {item.text}
                  </Text>
                </GridItem>
              </SimpleGrid>

              {index !== items.length - 1 && (
                <StoriesSeparator {...(index % 2 ? { transform: 'rotateX(180deg)' } : {})} />
              )}
            </Fragment>
          ))}
        </VStack>
      </LandingSectionContainer>
    </LandingSection>
  )
}
