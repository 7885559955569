import { Text, GridItem, SimpleGrid, VStack, Box } from '@chakra-ui/react'
import {
  LandingSection,
  LandingSectionContainer,
  LandingSectionCurvedDivider,
  LandingSectionTitle
} from '#/src/custom/components/Landing/LandingSection'

import ecaams1Img from './images/ecaams_1.webp'
import ecaams2Img from './images/ecaams_2.webp'
import ecaamsAIImg from './images/ecaams_ai.webp'
import { GrandImage } from 'ui'

const items = [
  {
    title: 'What is eCaaMS?',
    text: `Granditude's eCommerce-as-a-Managed-Service combines open-source flexibility, 
    SaaS affordability, and marketplace simplicity, enabling effortless business growth  ->`,
    img: ecaams1Img
  },
  {
    title: '',
    text: `Granditude AI bridges the gap in AI-enabled eCommerce, reducing risks and maximizing benefits. 
    Our solutions empower businesses to adopt AI seamlessly, driving smarter decisions, 
    improved customer experiences, and measurable growth.`,
    img: ecaams2Img
  }
]

export const SectionDo = () => {
  return (
    <LandingSection bg="landing.bg.primary.600" color="text.light" pb={0} gap={32}>
      <LandingSectionContainer gap={32}>
        <LandingSectionTitle>How do we do it?</LandingSectionTitle>

        <VStack alignItems="stretch" gap={36}>
          <SimpleGrid
            columns={{
              base: 1,
              sm: 2
            }}
            gap={24}
            alignItems="center">
            <GridItem order={1} position="relative" zIndex={1}>
              {!!items[0].title && (
                <Box
                  // textStyle="landingBody"
                  fontSize={{
                    base: '2xl',
                    sm: '3xl',
                    md: '4xl'
                  }}
                  mb="10">
                  {items[0].title}
                </Box>
              )}
              <Text
                textStyle="landingBody"
                fontSize={{
                  base: 'lg',
                  sm: 'xl',
                  md: '2xl'
                }}>
                {items[0].text}
              </Text>
            </GridItem>

            <GridItem order={2} position="relative" zIndex={0}>
              <GrandImage src={ecaamsAIImg} alt="" position="absolute" bottom="14%" right="56%" />
              <GrandImage src={items[0].img} alt="" />
            </GridItem>
          </SimpleGrid>

          <SimpleGrid
            columns={{
              base: 1,
              sm: 2
            }}
            gap={24}
            alignItems="center">
            <GridItem
              order={{
                base: 1,
                sm: 2
              }}>
              <Text
                textStyle="landingBody"
                fontSize={{
                  base: 'lg',
                  sm: 'xl',
                  md: '2xl'
                }}>
                {items[1].text}
              </Text>
            </GridItem>

            <GridItem
              order={{
                base: 2,
                sm: 1
              }}
              position="relative">
              <GrandImage src={items[1].img} alt="" />
            </GridItem>
          </SimpleGrid>
        </VStack>
      </LandingSectionContainer>
      <LandingSectionCurvedDivider curvedSide="top" />
    </LandingSection>
  )
}
